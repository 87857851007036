"use client";

import Link from "next/link";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { useFormState } from "react-hook-form";
import Image from "next/image";
import { motion } from "framer-motion";
import { FaTwitter, FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  const initialState = { message: "", errors: {} };
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const hoverEffect = {
    whileHover: { scale: 1.1 },
  };

  return (
    <footer className="relative bg-vibrant-violet-gradient py-12 dark:bg-gray-900 mt-0  shadow-lg">
      <motion.div
        className="container mx-auto px-4 md:px-6 relative z-10 backdrop-blur-md bg-white/10 border border-white/20 rounded-lg p-8"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
          <motion.div className="space-y-4" variants={itemVariants}>
            <Link href="/" passHref>
              <motion.div
                className="flex items-center space-x-2"
                {...hoverEffect}
              >
                <Image
                  src="/assets/logo.svg"
                  alt="Logo"
                  width={0}
                  height={0}
                  className="mb-1 filter invert dark:invert-0"
                  style={{ width: "70px", height: "auto" }}
                  priority
                />
                <span className="text-lg font-semibold text-white">
                  AiStudying
                </span>
              </motion.div>
            </Link>
            <p className="text-gray-200 text-sm">
              Stay up to date with the latest news and insights from our blog.
            </p>

            {/* Commented out social media icons */}
            {/* <div className="flex space-x-4">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
                className="bg-white p-1 rounded-full hover:opacity-80"
              >
                <FaTwitter size={24} color="#1DA1F2" />
              </a>
              <a
                href="https://github.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Github"
                className="bg-white p-1 rounded-full hover:opacity-80"
              >
                <FaGithub size={24} color="#181717" />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className="bg-white p-1 rounded-full hover:opacity-80"
              >
                <FaFacebook size={24} color="#1877F2" />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="bg-white p-1 rounded-full hover:opacity-80"
              >
                <FaLinkedin size={24} color="#0A66C2" />
              </a>
            </div> */}
          </motion.div>
          <motion.div className="space-y-4" variants={itemVariants}>
            <h3 className="text-lg font-semibold text-white">Blog</h3>
            <ul className="space-y-2 text-sm text-gray-200">
              {/* Commented out blog list items */}
              {/* <li>Latest Articles</li>
              <li>Popular Posts</li>
              <li>Categories</li> */}
            </ul>
          </motion.div>
          <motion.div className="space-y-4" variants={itemVariants}>
            <h3 className="text-lg font-semibold text-white">Links</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="/contact-us"
                  className="text-gray-200 hover:text-gray-400"
                >
                  Contact
                </a>
              </li>
              <li>
                <Link
                  href="/terms-and-conditions"
                  className="text-gray-200 hover:text-gray-400"
                >
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link
                  href="/privacy-policy"
                  className="text-gray-200 hover:text-gray-400"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  href="/sitemap.xml"
                  className="text-gray-200 hover:text-gray-400"
                >
                  Sitemap
                </Link>
              </li>
            </ul>
          </motion.div>
          <motion.div className="space-y-4" variants={itemVariants}>
            <h3 className="text-lg font-semibold text-white">Newsletter</h3>
            <p className="text-gray-200 text-sm">
              Subscribe to our newsletter to stay up-to-date with the latest
              news and updates.
            </p>
            <form>
              <div className="flex space-x-2">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  className="flex-1"
                  defaultValue=""
                  aria-describedby="email-error"
                />
                <Button className="bg-white text-green-700 hover:bg-gray-200">
                  Subscribe
                </Button>
              </div>
              <div
                id="email-error"
                aria-label="polite"
                aria-atomic="true"
                className="px-1 text-red-500"
              ></div>
            </form>
          </motion.div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-xs text-gray-200 dark:border-gray-700">
          &copy; {new Date().getFullYear()} AiStudying. All rights reserved.
        </div>
      </motion.div>
      <div className="footer-reflection"></div>
      <svg className="absolute inset-0 w-full h-full">
        <filter id="liquid">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
            result="liquid"
          />
          <feBlend in="SourceGraphic" in2="liquid" />
        </filter>
      </svg>
    </footer>
  );
}
